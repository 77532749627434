.dashboard-grid-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 30% 45% 25%;
    grid-template-areas:
        "list-wrapper-1 list-wrapper-2 list-wrapper-4"
        "list-wrapper-1 list-wrapper-3 list-wrapper-4"
        "list-wrapper-1 list-wrapper-3 list-wrapper-4"
        "list-wrapper-1 list-wrapper-3 list-wrapper-4"
        "list-wrapper-1 list-wrapper-3 list-wrapper-4";
    background-color: #fff;
    color: #444;
    justify-content: center;
    margin-top: 20px;
    padding: 0 25px 20px;
    height: calc(100vh - var(--desk-header-height) - 20px);
}

.list-wrapper-1 {
    grid-area: list-wrapper-1;
}

.list-wrapper-2 {
    grid-area: list-wrapper-2;
}

.list-wrapper-3 {
    grid-area: list-wrapper-3;
}

.list-wrapper-4 {
    grid-area: list-wrapper-4;
}

.grid-child {
    background-color: #444;
    border-radius: 5px;
    padding: 20px 0;
    overflow: hidden;
}

.list-wrapper-1 {
    background-color: #fff7eb;
}

.list-wrapper-2 {
    background-color: #dfe0df;
}

.list-wrapper-3 {
    background-color: #fff7eb;
}

.list-wrapper-4 {
    background-color: #fff7eb;
}

.dx-tr.table-data {
    z-index: 1;
}

.dashboard-table {
    margin-top: 10px;
}

.dx-td a.comments-btn {
    font-size: 30px;
}

.table-cell a.comments-btn {
    font-size: 20px;
}

.qr-sub-title {
    text-align: center;
    margin-top: 10px;
}

.scan-qr-btn-w {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.points-wrapper {
    font-size: 40px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: 700;
}

.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 30%;
    padding: 5px;
    border: 2px solid #c8986b;
    border-radius: 7px;
}

.popup-arrow {
    color: rgb(255, 255, 255);
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.8);
}

.modal>.header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal>.content {
    width: 100%;
    padding: 10px 5px;
}

.modal>.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
    margin-top: 50px;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 3px solid #a87c53;
    color: #a87c53;
}

.modal.qrmodal .content{
    text-align: center;
}

@media (max-width: 600px) {
    .dashboard-grid-container {
        margin-top: 0px;
        padding: 0 0 20px;
    }
}